import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import EmployeeForm from './pages/EmployeeForm'
import Home from './pages/Home'
import ServerSideTestTable from './pages/ServerSideTestTable'
import GlAccounts from './pages/GlAccounts'
import GlTrans from './pages/GlTrans'
import Vendor from './pages/Vendor'
import ReportsModule from './pages/ReportsModule'
import Budget from './pages/Budget'
import ReportBuilder from './pages/ReportBuilder'
import Assets from './pages/Assets'
import Po from './pages/Po'
import Item from './pages/Item'
import ItemLocation from './pages/ItemLocation'
import Balances from './pages/Balances'
import CashBookTrans from './pages/CashBookTrans'
import RequisitionInquiry from './pages/RequisitionInquiry'
import AssetAnalysis from './pages/AssetAnalysis'
import TransactionAnalysis from './pages/TransactionAnalysis'
import Admin from './pages/Admin'
import TrialBalance from './pages/TrialBalanceServerSide'
import Actrans from './pages/Actrans'
import InvoiceLookup from './pages/InvoiceLookup'
import PoLookup from './pages/PoLookup'
import CustomerActivity from './pages/CustomerActivity';
import GlTransServerSide from './pages/GlTransServerSide';
import AcTransServerSide from './pages/ActransServerSide';
import TransactionAnalysisServerSide from './pages/TransactionAnalysisServerSide';
import PoVendorInvoicePayment from './pages/PoVendorInvoicePayment'
import PayRollDistrib from './pages/PayrollDistrib'
import BillCost from './pages/BillCostAcParams';
import MetadataHelp from './pages/MetadataHelp'
import GlTransServerSideV2 from './pages/GlTransServerSideV2';
import ActransByActivityServerSide from './pages/ActransByActivityServerSide'
import AthenaReportBuilder from './pages/AthenaReportBuilder'

const permissionedPages = [
  {
    path: '/actrans',
    component: AcTransServerSide
  },
  {
    path: '/actrans-activity',
    component: ActransByActivityServerSide
  },
  {
    path: '/employee',
    component: EmployeeForm
  },
  {
    path: '/assets',
    component: Assets
  },
  {
    path: '/asset-analysis',
    component: AssetAnalysis
  },
  {
    path: '/transaction-analysis',
    component: TransactionAnalysisServerSide
  },
  {
    path: '/balances',
    component: Balances
  },
  {
    path: '/cash-book-trans',
    component: CashBookTrans
  },
  {
    path: '/serverside-test-table',
    component: ServerSideTestTable
  },
  {
    path: '/reports',
    component: ReportsModule
  },
  {
    path: '/gl-accounts',
    component: GlAccounts
  },
  {
    path: '/gl-trans',
    component: GlTransServerSideV2
  },
  {
    path: '/gl-trans-v2',
    component: GlTransServerSideV2
  },
  {
    path: '/item',
    component: Item
  },
  {
    path: '/item-loc',
    component: ItemLocation
  },
  {
    path: '/vendor',
    component: Vendor
  },
  {
    path: '/invoice-lookup',
    component: InvoiceLookup
  },
  {
    path: '/budget',
    component: Budget
  },
  {
    path: '/po',
    component: Po
  },
  {
    path: '/po-lookup',
    component: PoLookup
  },
  {
    path: '/payroll-distrib',
    component: PayRollDistrib
  },
  {
    path: '/requisition-inquiry',
    component: RequisitionInquiry
  },
  {
    path: '/trial-balances',
    component: TrialBalance
  },
  {
    path: '/customer-activity',
    component: CustomerActivity
  },
  {
    path: '/po-vendor',
    component: PoVendorInvoicePayment
  },
  {
    path: '/bill-cost',
    component: BillCost
  },
  {
    path: '/metadata',
    component: MetadataHelp
  },
  {
    path: '/athena-report-builder',
    component: AthenaReportBuilder
  },
]

export default class Routes extends React.Component {
  render () {
    const auth = this.props
    if (!auth.credentials) return null
    const pagePermission = auth.credentials.user.roleActions.reduce(
      (acc, cur) => {
        if (cur.type === 'page') {
          if (acc[cur.filter1]) {
            acc[cur.filter1] = acc[cur.filter1] ? true : cur.active
          } else {
            acc[cur.filter1] = cur.active === 1 ? true : false
          }
        }
        return acc
      },
      {}
    )
    return (
      <Switch>
        <Route
          exact
          path='/admin'
          render={props =>
            ( pagePermission['admin']) ? (
              <Admin
                {...props}
                fetchInitialData={auth}
                createSnack={auth.createSnack}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/'
                }}
              />
            )
          }
        />
        <Route
          exact
          path='/report-builder'
          render={props =>
            (process.env.REACT_APP_STAGE === 'DEV' && pagePermission['report-builder']) ? (
              <ReportBuilder
                {...props}
                fetchInitialData={auth}
                createSnack={auth.createSnack}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/'
                }}
              />
            )
          }
        />
        {permissionedPages
          .filter(p => pagePermission[p.path.slice(1)])
          .map(p => {
            const MyComponent = p.component
            return (
              <Route
                key={p.path}
                exact
                path={p.path}
                render={props => (
                  <MyComponent
                    {...props}
                    fetchInitialData={auth}
                    createSnack={auth.createSnack}
                  />
                )}
              />
            )
          })}
        <Route
          path='/'
          render={props => (
            <Home
              {...props}
              fetchInitialData={auth}
              createSnack={auth.createSnack}
            />
          )}
        />
      </Switch>
    )
  }
}
